<template>
  <el-card shadow="never">
    <template #header>
      <div class="card-header">
        <el-page-header @back="goBack">
          <template #content>
            <span class="text-large font-600 mr-3"> 公告 </span>
          </template>
          <template #extra>
            <div class="flex items-center">
              <el-button @click="shownotice" type="primary">添加</el-button>
            </div>
          </template>
        </el-page-header>
      </div>
    </template>
    <div>
      <el-form :model="searchData" inline label-width="80px">
        <el-form-item label="时间">
          <el-date-picker
            v-model="searchData.addtime"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="请选择时间"
          />
        </el-form-item>
        <el-form-item label="关键词">
          <el-input v-model="searchData.words" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="id" width="55" />
      <el-table-column prop="title" label="标题" width="150" />
      <el-table-column prop="content" label="留言" />
      <el-table-column prop="addTime" label="添加时间" width="160" />
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="small" @click="editnotice(scope.row)">编辑</el-button>
          <el-button
            size="small"
            type="danger"
            @click="delnotice(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="float:right;margin:20px">
      <el-pagination
        v-model:currentPage="current_page"
        v-model:page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-card>
  <el-dialog v-model="noticebox" :title="title">
    <el-form :model="formData">
      <el-form-item label="标题" label-width="120px">
        <el-input v-model="formData.title" placeholder="请输入标题" autocomplete="off" />
      </el-form-item>
      <el-form-item label="内容" label-width="120px">
        <el-input
          v-model="formData.content"
          :autosize="{ minRows: 4, maxRows: 10 }"
          type="textarea"
          placeholder="请输入内容"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="noticebox = false">取消</el-button>
        <el-button type="primary" @click="savenotice">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import api from '@/api/index.js'
export default defineComponent({
setup() {
  const searchData = ref({
    words:'',
    addtime:'',
    page:1,
  })
  const formData = ref({
    id:'',
    title:'',
    content:'',
  })
  const handleSelectionChange = () => {
    console.log('go back')
  }
  const title = ref('')
  const noticebox = ref(false)
  const tableData = ref([])
  const pageSize = ref(10)
  const total = ref(0)
  const current_page = ref(1)
  return {
    formData,
    tableData,
    searchData,
    pageSize,
    total,
    title,
    current_page,
    noticebox,
    handleSelectionChange
  };
},
methods: {
  shownotice(){
    this.title = '新建公告'
    this.noticebox = true
    this.formData.id = ''
    this.formData.title = ''
    this.formData.content = ''
  },
  savenotice(){
    let that = this
    api.noticeApi.savenotice(this.formData).then(res => {
        if (res.code === 0) {
          ElMessage({
            message: res.message,
            type: 'success',
          })
          this.noticebox = false
          that.getList()
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
  goBack(){
    this.$router.go(-1)
  },
  handleCurrentChange(val){
    this.searchData.page = val
    this.getList()
  },
  deleteLevel(id){
    let that = this
    api.memberApi.deleteLevel({id:id}).then(res => {
        if (res.code === 0) {
          ElMessage({
            message: res.message,
            type: 'success',
          })
          that.getList()
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
  search(){
    this.searchData.page=1
    this.getList()
  },
  getList(){
    let that = this
    api.noticeApi.getList(this.searchData).then(res => {
        if (res.code === 0) {
          that.pageSize = res.data.per_page
          that.tableData = res.data.data
          that.total = res.data.total
          that.current_page = res.data.current_page
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
  editnotice(data){
    this.title = '编辑公告'
    this.noticebox = true
    this.formData.id = data.id
    this.formData.title = data.title
    this.formData.content = data.content
  },
  delnotice(id){
    let that = this
    api.noticeApi.delnotice({id:id}).then(res => {
        if (res.code === 0) {
            ElMessage({
              message: res.message,
              type: 'success',
            })
            that.getList()
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
},
mounted () {
  this.getList()
}
})
</script>
<style>

</style>